import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  // 边民互贸 、 二连口岸
  {
    path: '/global/:hsCode',
    name: 'HomeBianMin',
    component: () => import('../views/HomeBianMin'),
    meta: {
      requireAuth: true
    }
  },

  // 俄罗斯 转口 贸易国家
  {
    path: '/russia/:hsCode',
    name: 'ZhuanKou',
    component: () => import('../views/RussiaNew'),
    meta: {
      requireAuth: true
    }
  },


  // 首页
  // {
  //   path: '/',
  //   name: 'Home',
  //   component: () => import('../views/Home'),
  //   meta: {
  //     requireAuth: true
  //   }
  // },

  // 中国
  {
    path: '/china',
    name: 'China',
    component: () => import('../views/china'),
    meta: {
      requireAuth: true
    }
  },

  // 俄罗斯 出口 转口 总额
  {
    path: '/russia-old',
    name: 'Russia',
    component: () => import('../views/russia'),
    meta: {
      requireAuth: true
    }
  },

  // hs10 商品信息
  {
    path: '/product',
    name: 'Product',
    component: () => import('../views/product'),
    meta: {
      requireAuth: true
    }
  },
  
]

const router = new VueRouter({
  // mode: 'hash',
  routes
})

// router.beforeEach((to, from, next) => {
//   let token = window.sessionStorage.getItem('token')
//   // 判断该路由是否需要登录权限
//   if (to.meta.requireAuth) {
//     if (token) {
//       next()
//     } else {
//       next('/login')
//     }
//   } else {
//     next()
//   }
// })

export default router
