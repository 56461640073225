<template>
  <div class="full relative">
    <router-view/>
  </div>
</template>

<script>
export default {
  data() {
    return {

    }
  },
  async created() {
    
  },
  mounted() {
    
  },
  methods: {

  }
}
</script>

