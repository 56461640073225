import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: '',
    webSocketCallMsg: null,
    webSocketCallState: null,

    audioInputList: [],
    videoInputList: [],
    microphoneDeviceVal: null,
    cameraDeviceVal: null
  },
  mutations: {
    setToken(state, val) {
      state.token = val
    },
    setWebSocketCallMsg(state, val) {
      state.webSocketCallMsg = val
    },
    setWebSocketCallState(state, val) {
      state.webSocketCallState = val
    },
    setAudioInputList(state, val) {
      state.audioInputList = val
    },
    setVideoInputList(state, val) {
      state.videoInputList = val
    },
    setMicrophoneDeviceVal(state, val) {
      state.microphoneDeviceVal = val
    },
    setCameraDeviceVal(state, val) {
      state.cameraDeviceVal = val
    },
  },
  actions: {
  },
  modules: {
  }
})
